import { FunctionComponent, useMemo, useEffect } from "react";
import CSS, { Property } from "csstype";
import styles from "./ContainerCybersecurityProjects.module.css";

type ContainerCybersecurityProjectsType = {
  cybersecurityProjectsText?: string;
  serviceGuaranteeText?: string;
  percentageText?: string;
  cybersecurityProjectsNumb?: string;

  /** Style props */
  propLeft?: Property.Left;
  propLeft1?: Property.Left;
  propLeft2?: Property.Left;
};

const ContainerCybersecurityProjects: FunctionComponent<
  ContainerCybersecurityProjectsType
> = ({
  cybersecurityProjectsText,
  serviceGuaranteeText,
  percentageText,
  cybersecurityProjectsNumb,
  propLeft,
  propLeft1,
  propLeft2,
}) => {
  const divStyle: CSS.Properties = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  const div1Style: CSS.Properties = useMemo(() => {
    return {
      left: propLeft1,
    };
  }, [propLeft1]);

  const cybersecurityProjectsStyle: CSS.Properties = useMemo(() => {
    return {
      left: propLeft2,
    };
  }, [propLeft2]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div
      className={styles.divjegElementorKit}
      id="CounterBoxCard1"
      data-animate-on-scroll
    >
      <div className={styles.divfunFactInner}>
        <img
          className={styles.frameIcon}
          alt=""
          src={cybersecurityProjectsText}
        />
        <div className={styles.divcontent}>
          <div className={styles.divnumberWrapper}>
            <div className={styles.div} style={divStyle}>
              {serviceGuaranteeText}
            </div>
            <div className={styles.div1} style={div1Style}>
              {percentageText}
            </div>
          </div>
          <div
            className={styles.cybersecurityProjects}
            style={cybersecurityProjectsStyle}
          >
            {cybersecurityProjectsNumb}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerCybersecurityProjects;
