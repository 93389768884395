import { FunctionComponent, useCallback, useEffect, useState } from "react";
import styles from "./HomeContainer.module.css";
import { Link } from "react-scroll";

const HomeContainer: FunctionComponent = () => {
  
  // CustomCODE: state
  const [openMenuClicked, setOpenMenuClicked] = useState<boolean>(false);

  // CustomCODE: open mobile function
  const openMobileMenu = (value: boolean) => {
    //
    setOpenMenuClicked(!value);

    console.log("openMobileMenu", value);
  };

  const onAboutUsClick = useCallback(() => {

    // CustomCODE: close mobile menu
    setOpenMenuClicked(false);

    const anchor = document.querySelector(
      "[data-scroll-to='welcomeToTelcometrics']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onContactClick = useCallback(() => {

    // CustomCODE: close mobile menu
    setOpenMenuClicked(false);

    const anchor = document.querySelector("[data-scroll-to='contactSection']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div className={styles.sectionelementorSection}>
      <div className={styles.divelementorContainerParent}>
        <div className={styles.divelementorContainer}>
          <div className={styles.frameParent}>
            <div className={styles.groupParent}>
              <img className={styles.frameChild} alt="" src="/group-25.svg" />
              <img
                className={styles.telcometricsIcon}
                alt=""
                src="/telcometrics.svg"
              />
            </div>
            <div className={styles.frameGroup}>
              <button 
                className={styles.vectorWrapper}
                onClick={() => { openMobileMenu(openMenuClicked); }}
              >
                <img className={styles.vectorIcon} alt="" src="/vector.svg" />
              </button>
              <div className={styles.ulmenuMenu1}>
                <div className={styles.homeParent}>
                  {/* <a className={styles.home}>Home</a>
                  <a className={styles.aboutUs} onClick={onAboutUsClick}>
                    About Us
                  </a>
                  <a className={styles.aboutUs} onClick={onContactClick}>
                    Contact
                  </a> */}
                  <Link
                    duration={500}
                    activeClass="active"
                    to="mainWrapper"
                    spy={true}
                    smooth={true}
                    className={styles.home}
                  >
                    Home
                  </Link>
                  <Link
                    duration={900}
                    //activeClass="active"
                    to="welcomeToTelcometrics"
                    spy={true}
                    smooth={true}
                    className={styles.aboutUs}
                  >
                    About Us
                  </Link>
                  <Link
                    duration={1300}
                    //activeClass="active"
                    to="contactSection"
                    spy={true}
                    smooth={true}
                    className={styles.aboutUs}
                  >
                    Contact
                  </Link>
                </div>
              </div>
              <button style={{ display: 'none'}} className={styles.aelementorButtonLink}>
                <div className={styles.contactUs}>Contact Us</div>
              </button>
            </div>
          </div>
        </div>
        <div 
          style={{
            display: openMenuClicked ? "block" : "none",
          }} 
          className={styles.menuMobile}
        >
          <div className={styles.frameContainer}>
            <div 
              className={styles.vectorContainer}
              style={{ cursor: 'pointer' }}
              onClick={() => { openMobileMenu(openMenuClicked); }}
            >
              <img className={styles.vectorIcon1} alt="" src="/vector1.svg" />
            </div>
            <div className={styles.homeGroup}>
              {/* <b className={styles.home1}>Home</b>
              <div className={styles.aboutUs1}>About Us</div>
              <div className={styles.contact1}>Contact</div> */}
              <Link
                    duration={500}
                    activeClass="active"
                    to="mainWrapper"
                    spy={true}
                    smooth={true}
                    className={styles.home1}
                  >
                    Home
                  </Link>
                  <Link
                    duration={1000}
                    //activeClass="active"
                    to="welcomeToTelcometrics"
                    spy={true}
                    smooth={true}
                    className={styles.aboutUs1}
                  >
                    About Us
                  </Link>
                  <Link
                    duration={1400}
                    //activeClass="active"
                    to="contactSection"
                    spy={true}
                    smooth={true}
                    className={styles.contact1}
                  >
                    Contact
                  </Link>
            </div>
          </div>
          <div style={{ display: 'none' }} className={styles.aelementorButtonLink1}>
            <div className={styles.contactUs1}>Contact Us</div>
          </div>
        </div>
        <div className={styles.divelementorBackgroundOverl} />
        <div className={styles.frameWrapper}>
          <div className={styles.divelementorWidgetWrapParent}>
            <div className={styles.divelementorWidgetWrap}>
              <div className={styles.divelementorWidgetWrap1}>
                <h1
                  className={styles.telecomSoftwareDevelopmentContainer}
                  data-animate-on-scroll
                >
                  <span>{`Telecom software `}</span>
                  <span className={styles.developmentAndCompliance}>
                    development and compliance system
                  </span>
                </h1>
                <div
                  className={styles.securitypngWrapper}
                  data-animate-on-scroll
                >
                  <img
                    className={styles.securitypngIcon}
                    alt=""
                    src="/securitypng@2x.png"
                  />
                </div>
                <div
                  className={styles.divelementorWidgetContainer}
                  data-animate-on-scroll
                >
                  <div className={styles.atTelcometricsOur}>
                  Telcometrics is dedicated to providing our esteemed customers with IT consulting and a comprehensive software house experience. With a strong track record spanning several years, we have consistently delivered exceptional Telecom software development services, including maintenance and support, while maintaining a steadfast commitment to upholding the highest standards of quality and excellence.
                  </div>
                </div>
                <div
                  className={styles.aelementorButtonLinkWrapper}
                  data-animate-on-scroll
                >
                <Link
                  duration={500}
                  //activeClass="active"
                  to="welcomeToTelcometrics"
                  spy={true}
                  smooth={true}
                  className={styles.aelementorButtonLink2}
                >
                  <div className={styles.learnMore}>Learn More</div>
                </Link>
                  <button style={{ display: 'none' }} className={styles.aelementorButtonLink2}>
                    <div className={styles.learnMore}>Learn More</div>
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.securitypngContainer} data-animate-on-scroll>
              <img
                className={styles.securitypngIcon1}
                alt="..."
                loading="lazy"
                src="/securitypng1@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContainer;
