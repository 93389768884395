import { FunctionComponent, useMemo, useEffect } from "react";
import CSS, { Property } from "csstype";
import styles from "./FrameComponent.module.css";

type FrameComponentType = {
  databasepng?: string;
  consulting?: string;
  p?: string;
  rOFITFROMOUREXPERTADVICET?: string;
  icon?: string;
  animDelay?: string;

  /** Style props */
  propBorder?: Property.Border;
  propWidth?: Property.Width;
  propAlignItems?: Property.AlignItems;
};

const FrameComponent: FunctionComponent<FrameComponentType> = ({
  databasepng,
  consulting,
  animDelay,
  p,
  rOFITFROMOUREXPERTADVICET,
  icon,
  propBorder,
  propWidth,
  propAlignItems,
}) => {
  const divjkitIconBoxWrapperStyle: CSS.Properties = useMemo(() => {
    return {
      border: propBorder,
      animationDelay: animDelay,
    };
  }, [propBorder]);

  const consultingStyle: CSS.Properties = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const frameDiv1Style: CSS.Properties = useMemo(() => {
    return {
      alignItems: propAlignItems,
    };
  }, [propAlignItems]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div 
    className={styles.divjkitIconBoxWrapperWrapper} 
    data-animate-on-scroll
    style={divjkitIconBoxWrapperStyle}
    >
      <div
        className={styles.divjkitIconBoxWrapper}
        //id="OfferBoxCard1"
        //style={divjkitIconBoxWrapperStyle}
      >
        <img className={styles.databasepngIcon} alt="" src={databasepng} />
        <img
          className={styles.databasepngIcon1}
          alt=""
          src={icon}
        />
        <div className={styles.diviconBox}>
          <div className={styles.consultingParent}>
            <div className={styles.consulting} style={consultingStyle}>
              {consulting}
            </div>
            <div className={styles.piconBoxDescription}>
              <div className={styles.profitFromOurContainer}>
                <span className={styles.p}>{p}</span>
                <span className={styles.rofitFromOur}>
                  {rOFITFROMOUREXPERTADVICET}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.parent} style={frameDiv1Style}>
            <div className={styles.div}>
      <img className={styles.frameChild} alt="" src="/vector-7.svg" /></div>
            <div className={styles.readMore}>Read More</div>
            <div className={styles.div1}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
