import React from 'react';
import { FunctionComponent, useMemo, useEffect } from "react";
import { useSwiper } from 'swiper/react';
import styles from "../../pages/TelcometricsReactWeb02.module.css";

type SwiperNavButtonsType = {
    buttonType?: string;
  };

export const SwiperNavButtons: FunctionComponent<SwiperNavButtonsType> = ({ 
    buttonType, 
}) => {

  const swiper = useSwiper();

  return (
    <div style={{ marginLeft: '15px', marginBottom: '15px' }} className="swiper-nav-btns">
      {/*<button onClick={() => swiper.slidePrev()}>Prev</button>
      <button onClick={() => swiper.slideNext()}>Next</button>*/}

      {buttonType === "next" && (
      <button
                          // CustomCODE: onClick function
                          onClick={() => { 
                            swiper.slideNext();
                          }}
                          className={styles.aelementorButton}
                          data-animate-on-scroll
                        >
                          <div className={styles.readMore}>read More</div>
                        </button>
        )}
        {buttonType === "prev" && (
      <button
                            // CustomCODE: onClick function
                            onClick={() => { 
                                swiper.slidePrev(); 
                            }}
                            className={styles.aelementorButton1}
                            data-animate-on-scroll
                          >
                            <div className={styles.vectorParent}>
                              <img
                                className={styles.vectorIcon}
                                alt=""
                                src="/vector2.svg"
                              />
                              <div className={styles.readMore}>back</div>
                            </div>
                          </button>
        )}
    </div>
  );
};