import { FunctionComponent, useMemo } from "react";
import CSS, { Property } from "csstype";
import styles from "./ContainerHighQualityService.module.css";

type ContainerHighQualityServiceType = {
  serviceText?: string;
  protectionText?: string;

  /** Style props */
  propWidth?: Property.Width;
};

const ContainerHighQualityService: FunctionComponent<
  ContainerHighQualityServiceType
> = ({ serviceText, protectionText, propWidth }) => {
  const highQualityServiceStyle: CSS.Properties = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div className={styles.divjkitIconBoxWrapper}>
      <img className={styles.cloud150x150pngIcon} alt="" src={serviceText} />
      <div className={styles.diviconBox}>
        <div
          className={styles.highQualityService}
          style={highQualityServiceStyle}
        >
          {protectionText}
        </div>
        <div className={styles.piconBoxDescription}>
          <div className={styles.loremIpsumDolor}>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerHighQualityService;
