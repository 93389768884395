import { FunctionComponent, useEffect, useState, useRef } from "react";
import HomeContainer from "../components/HomeContainer";
import CardAssess from "../components/CardAssess";
import ContainerHighQualityService from "../components/ContainerHighQualityService";
import ContainerCybersecurityProjects from "../components/ContainerCybersecurityProjects";
import FrameComponent2 from "../components/FrameComponent2";
import FrameComponent3 from "../components/FrameComponent3";
import FrameComponent from "../components/FrameComponent";
import FrameComponent1 from "../components/FrameComponent1";
import Footer from "../components/Footer";
import styles from "./TelcometricsReactWeb02.module.css";
import emailjs from '@emailjs/browser';
import Counter from "../@core/components/Counter";

// Import Swiper React components
import { Navigation, FreeMode, Pagination, Scrollbar, A11y, Controller } from 'swiper';
import { SwiperNavButtons } from '../@core/components/SwiperNavButton';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const fieldMessagesData = {
  email: "Please enter a valid email address.",
  name: "Please enter your name.",
  message: "Please enter a message.",
  good: "Looks good!",
  empty: "some data is missing.",
};

const sendMessagesData = {
  success: "Thank you. We will get back to you shortly.",
  error: "Something went wrong. Please try again later.",
};

const TelcometricsReactWeb02: FunctionComponent = () => {

  // CustomCODE: state
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [hideFieldMessage, setHideFieldMessage] = useState<boolean>(false);

  const [swiperRef, setSwiperRef] = useState(null);

  // CustomCODE:
  //const contactForm = useRef<HTMLInputElement>(null);

  const [fieldMessages, setFieldMessages] = useState<object>(fieldMessagesData);
  const [sendMessages, setSendMessages] = useState<object>(sendMessagesData);

  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const [countersStarted, setCountersStarted] = useState<boolean>(false);

  const [sendFormEmail, setSendFormEmail] = useState<boolean>(false);
  const [sendFormName, setSendFormName] = useState<boolean>(false);
  const [sendFormMessage, setSendFormMessage] = useState<boolean>(false);


  const [sendForm, setSendForm] = useState<string>('');

  const [sendFormEmailVal, setSendFormEmailVal] = useState<string>('');
  const [sendFormNameVal, setSendFormNameVal] = useState<string>('');
  const [sendFormMessageVal, setSendFormMessageVal] = useState<string>('');

  const [checkEmail, setCheckEmail] = useState<boolean>(false);
  const [checkName, setCheckName] = useState<boolean>(false);
  const [checkMessage, setCheckMessage] = useState<boolean>(false);

  const [readMoreClickActionType, setReadMoreClickActionType] = useState<string>('back');
  const [readMoreClickActionVal, setReadMoreClickActionVal] = useState<boolean>(true);

  const readMoreClickAction = (type: string, value: boolean) => {
    //
    setReadMoreClickActionType(type);
    setReadMoreClickActionVal(value);
    //
    // console.log("readMoreClickAction type: " + type);
    // console.log("readMoreClickAction value: " + value);
  }

  // check email validation with @ and . check
  const checkEmailValidation = (email: string) => {
    if (email.indexOf('@') > -1 && email.indexOf('.') > -1) {
       console.log("checkEmailValidation: " + true);
      setCheckEmail(true);
      return true;
    }
     console.log("checkEmailValidation: " + false);
    setCheckEmail(false);
    return false;
  }

  // check name validation with @ and . check
  const checkNameValidation = (name: string) => {
    if (name.length > 1) {
       console.log("checkNameValidation: " + true);
      setCheckName(true);
      return true;
    }
     console.log("checkNameValidation: " + false);
    setCheckName(false);
    return false;
  }

  // check message validation with @ and . check
  const checkMessageValidation = (message: string) => {
    if (message.length > 1) {
       console.log("checkMessageValidation: " + true);
      setCheckMessage(true);
      return true;
    }
     console.log("checkMessageValidation: " + false);
    setCheckMessage(false);
    return false;
  }

  const sendEmail = (e: any) => {
    e.preventDefault();

    const templateParams = {
      user_email: sendFormEmailVal,
      user_name: sendFormNameVal,
      from_name: 'Telcometrics Website',
      user_message: sendFormMessageVal,
    };

    setSendFormEmailVal('');
    setSendFormNameVal('');
    setSendFormMessageVal('');

    setCheckEmail(false);

    setSendFormEmail(false);
    setSendFormName(false);
    setCheckMessage(false);


    emailjs.send('service_6o7bnrd','template_ybm6b4g', templateParams, 'b0QZOzwyMLLdQJrX1')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setSendForm(response.text);
      setTimeoutMessage();
    }, (err) => {
      console.log('FAILED...', err);
      setSendForm(err.text);
    });

    /*emailjs.sendForm('service_6o7bnrd', 'template_ybm6b4g', '#contactForm', 'b0QZOzwyMLLdQJrX1')
      .then((result) => {
           //console.log(result.text);
           setSendForm(result.text);
      }, (error) => {
           //console.log(error.text);
           setSendForm(error.text);
      });*/
  };

  const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      if (position > 1450) {
        setCountersStarted(true);
      }

      // console.log("scrollPosition: " + position);
  };


  // settimeout for text function
  const setTimeoutMessage = () => {
    // settimeout for text
    setTimeout(() => {
      setHideFieldMessage(true);
    }, 10000);
  };



  useEffect(() => {

    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      console.log("windowSize: " + windowSize);
    };

    window.addEventListener('resize', handleWindowResize);

    // CustomCODE: scroll
    window.addEventListener('scroll', handleScroll, { passive: true });



    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div className={styles.telcometricsreactweb02} id="mainWrapper">
      <div className={styles.divpage}>
        <HomeContainer />
        <div className={styles.divpageInner} data-animate-on-scroll>
          <div className={styles.ellipseParent}>
            <div className={styles.groupChild} />
            <img className={styles.image4Icon} alt="" src="/image-4@2x.png" />
          </div>
        </div>
        <div className={styles.divelementor}>
          <div className={styles.divelementorContainerParent}>
            <div className={styles.divelementorContainer}>
              <div className={styles.divelementorContainerChild} />
            </div>
            <div className={styles.divelementorBackgroundOverl} />
            <div className={styles.needssectionParent}>
              <section className={styles.needssection} id="needsSection">
                <div className={styles.divpageInner} data-animate-on-scroll>
                  <h2 className={styles.weAnalyseOurContainer}>
                    <p className={styles.weAnalyseOur}>
                      We analyse our client’s needs to prevent, detect
                    </p>
                    <p className={styles.weAnalyseOur}>
                      and investigate their organisation’s needs
                    </p>
                  </h2>
                </div>
                <div className={styles.frameParent} id="cardOpacityOne">
                  <div className={styles.frameGroup}>
                    <div
                      className={styles.divjkitIconBoxWrapperWrapper}
                      data-animate-on-scroll
                    >
                      <CardAssess
                        assessmentDimensions="/1.svg"
                        assessmentText="We assess"
                        text="We conduct thorough assessments to ensure comprehensive understanding of our clients' needs and challenges before delivering tailored telecom software solutions."
                        br={0}
                      />
                    </div>
                    <div
                      className={styles.divjkitIconBoxWrapperContainer}
                      data-animate-on-scroll
                    >
                      <CardAssess
                        assessmentDimensions="/11.svg"
                        assessmentText="We investigate"
                        propWidth="2.17rem"
                        propHeight="1.88rem"
                        propWidth1="unset"
                        propLeft="unset"
                        propWidth2="unset"
                        text="We conduct in-depth investigations to identify and address any issues or concerns, ensuring that our telecom software solutions are effective and reliable."
                        br={1}
                      />
                    </div>
                  </div>
                  <div className={styles.frameGroup}>
                    <div
                      className={styles.divjkitIconBoxWrapperFrame}
                      data-animate-on-scroll
                    >
                      <CardAssess
                        assessmentDimensions="/2.svg"
                        assessmentText="We analyse"
                        propWidth="2.34rem"
                        propHeight="1.88rem"
                        propWidth1="unset"
                        propLeft="unset"
                        propWidth2="unset"
                        text="Through meticulous analysis, we examine data and trends to gain valuable insights, enabling us to develop telecom software solutions that optimize performance and enhance efficiency."
                        br={0}
                      />
                    </div>
                    <div className={styles.frameDiv} data-animate-on-scroll>
                      <CardAssess
                        assessmentDimensions="/12.svg"
                        assessmentText="We advise"
                        propWidth="2.11rem"
                        propHeight="1.64rem"
                        propWidth1="unset"
                        propLeft="unset"
                        propWidth2="unset"
                        text="We provide insightful advice and guidance to our clients, helping them make informed decisions and achieve their telecom objectives effectively."
                        br={2}
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.welcomesection} id="welcomeSection">
                <div className={styles.frameParent1}>
                  
      <Swiper
        autoHeight={true}
        grabCursor={true}
        slidesPerView={1}
        spaceBetween={30}
        freeMode={false}
        pagination={{
          clickable: true,
          
        }}
        modules={[FreeMode, Pagination]}
        //className="mySwiper"
        style={{ width: windowSize[0]-80, maxWidth: '1140px' }}
        breakpoints={{
          420: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          960: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
      >
      <SwiperNavButtons />
      <SwiperSlide>
                  <div className={styles.frameParent2}>
                    <div className={styles.frameParent3}>
                      <div className={styles.frameParent4}>
                        <div className={styles.welcomeToTelcometricsParent}>
                          <div
                            id="welcomeToTelcometrics"
                            //className={styles.welcomeToTelcometrics}
                            data-scroll-to="welcomeToTelcometrics"
                            data-animate-on-scroll
                          >
                          </div>
                          <div
                            className={styles.telecomSoftwareDevelopment}
                            data-animate-on-scroll
                          >
                            Telecom software development and fraud management
                          </div>
                        </div>
                        <div
                          className={styles.divelementorBackgroundOverl1}
                          data-animate-on-scroll
                        >
                          <img
                            className={styles.image1Icon}
                            alt=""
                            src="/image-1@2x.png"
                          />
                        </div>
                        <div
                          className={styles.ourTeamOfContainer}
                          data-animate-on-scroll
                        >
                          <p className={styles.weAnalyseOur}>
                          Our proficient team of consultants and IT professionals delivers dependable services and products across various domains of telecom software development. Leveraging our technical expertise, we specialize in crafting tailored solutions that empower our clients.
                          </p>
                          <p className={styles.weAnalyseOur}>&nbsp;</p>
                          <p className={styles.weAnalyseOur}>
                          Our offerings include real-time threat level reporting, cutting-edge fraud management systems, payment systems, and mobile communications, all designed to enhance our clients' capabilities.
                          </p>
                        </div>
                      </div>
                      <div className={styles.divelementorContainer1}>
                        <div className={styles.frameParent5}>
                          <div
                            className={styles.divjkitIconBoxWrapperWrapper1}
                            data-animate-on-scroll
                          >
                            <ContainerHighQualityService
                              serviceText="/images/cloud.png"
                              protectionText="High Quality Service"
                            />
                          </div>
                          <div
                            className={styles.divjkitIconBoxWrapperWrapper2}
                            data-animate-on-scroll
                          >
                            <ContainerHighQualityService
                              serviceText="/images/asser2x-150x150.png"
                              protectionText="Effective Protection"
                              propWidth="unset"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.aelementorButtonWrapper}
                        data-animate-on-scroll
                      >
                        {/* <button className={styles.aelementorButton}>
                          <div className={styles.readMore}>read More</div>
                        </button> */}
                        <SwiperNavButtons
                          buttonType="next"
                        />
                      </div>
                    </div>
                    <div
                      className={styles.divelementorBackgroundOverl2}
                      data-animate-on-scroll
                    >
                      <img
                        className={styles.image1Icon1}
                        alt=""
                        src="/image-1@2x.png"
                      />
                    </div>
                  </div>
                  </SwiperSlide>
                  <SwiperSlide>
                  <div className={styles.frameParent6}>
                    <div className={styles.divelementorWidgetContainerWrapper}>
                      <div className={styles.welcomeToTelcometricsParent}>
                        <div className={styles.frameParent4}>
                          <div className={styles.welcomeToTelcometricsParent}>
                            <div
                              className={styles.welcomeToTelcometrics}
                              data-animate-on-scroll
                            >
                              Leading the way
                            </div>
                            <div
                              className={styles.companyHistory}
                              data-animate-on-scroll
                            >
                              Company history
                            </div>
                          </div>
                          <div
                            className={styles.divelementorBackgroundOverl3}
                            data-animate-on-scroll
                          >
                            <div className={styles.frame}>
                              <img
                                className={styles.groupIcon}
                                alt=""
                                src="/group.svg"
                              />
                            </div>
                            <img
                              className={styles.image5Icon}
                              alt=""
                              src="/image-502.png"
                            />
                          </div>
                          <div
                            className={
                              styles.telcometricsWasEstablishedContainer
                            }
                            data-animate-on-scroll
                          >
                            <p className={styles.telcometricsWasEstablished}>
                            Telcometrics is a dynamic and innovative telecom software company, comprising a diverse and talented network of specialists based in Hong Kong and across the globe. We excel in providing robust protection for our clients, understanding the critical importance of safeguarding sensitive telecommunications data. 
                            </p>
                            <p className={styles.weAnalyseOur}>&nbsp;</p>
                            <p className={styles.weAnalyseOur}>
                            Our expertise spans various domains, including network architecture, software development, security, and regulatory compliance, allowing our team to tackle complex challenges with confidence and precision.
                            </p>
                            <p className={styles.weAnalyseOur}>&nbsp;</p>
                            <p className={styles.weAnalyseOur}>
                            With state-of-the-art security measures and continuous monitoring, we stay ahead of evolving threats to offer our clients peace of mind. Our team's adaptability enable us to meet the ever-evolving needs of our clients, delivering reliable and secure telecommunications solutions tailored to their specific requirements. 
                            </p>
                            <p className={styles.weAnalyseOur}>&nbsp;</p>
                            <p className={styles.weAnalyseOur}>
                            With our extensive experience and commitment to excellence, we aim to foster long-lasting relationships with our valued clients, delivering exceptional results that drive their success.
                            </p>
                            <p className={styles.weAnalyseOur}>&nbsp;</p>
                                                      </div>
                        </div>
                        <div
                          className={styles.aelementorButtonContainer}
                          data-animate-on-scroll
                        >
                          {/* <button className={styles.aelementorButton1}>
                            <div className={styles.vectorParent}>
                              <img
                                className={styles.vectorIcon}
                                alt=""
                                src="/vector2.svg"
                              />
                              <div className={styles.readMore}>back</div>
                            </div>
                          </button> */}
                        <SwiperNavButtons
                          buttonType="prev"
                        />
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.divelementorBackgroundOverl4}
                      data-animate-on-scroll
                    >
                      <img
                        className={styles.frameIcon}
                        alt=""
                        src="/frame.svg"
                      />
                      <img
                        className={styles.image5Icon}
                        alt=""
                        src="/image-502.png"
                      />
                    </div>
                  </div>
                  </SwiperSlide>
                  </Swiper>
                </div>
              </section>
              <section className={styles.counterssection} id="countersSection">
                <div className={styles.sectionelementorSection}>
                  <div className={styles.divelementorContainer2}>
                    <div className={styles.divjegElementorKitParent}>
                      <ContainerCybersecurityProjects
                        cybersecurityProjectsText="/frame1.svg"
                        serviceGuaranteeText="3,452 "
                        percentageText="+"
                        cybersecurityProjectsNumb="Cybersecurity Projects"
                      />
                      <div
                        className={styles.divjegElementorKit}
                        id="CounterBoxCard2"
                        data-animate-on-scroll
                      >
                        <div className={styles.divfunFactInner}>
                          <img
                            className={styles.frameIcon1}
                            alt=""
                            src="/frame2.svg"
                          />
                          <div className={styles.divcontent}>
                            <div className={styles.divnumberWrapper}>
                              <div className={styles.div}>{`1,458 `}</div>
                              <div className={styles.div1}>+</div>
                            </div>
                            <div className={styles.clientsProtection}>
                              Clients Protection
                            </div>
                          </div>
                        </div>
                      </div>
                      <ContainerCybersecurityProjects
                        cybersecurityProjectsText="/frame3.svg"
                        serviceGuaranteeText="100 "
                        percentageText="%"
                        cybersecurityProjectsNumb="Service Guarantee"
                        propLeft="5.73rem"
                        propLeft1="9.61rem"
                        propLeft2="4.02rem"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section
                className={styles.industriessection}
                id="industriesSection"
              >
                <div className={styles.divelementorContainer3}>
                  <FrameComponent3
                    vector="/Fintech.png"
                    text="Fintech"
                    propDisplay="flex"
                  />
                  <FrameComponent3
                    vector="/Banking.png"
                    text="Banking"
                    propDisplay="flex"
                  />
                  <FrameComponent3
                    vector="/Telecom.png"
                    text="Telecom"
                    propDisplay="flex"
                  />
                  <FrameComponent3
                    vector="/Fortune500.png"
                    text="Fortune 500"
                    propDisplay="flex"
                  />
                  <FrameComponent3
                    vector="/PublicSector.png"
                    text="Public sector organisations"
                    propDisplay="flex"
                  />
                </div>
              </section>
              <section className={styles.offersection} id="offerSection">
                <div className={styles.ourServicesParent}>
                  <div className={styles.ourServices} data-animate-on-scroll>
                    Our Services
                  </div>
                  <div className={styles.whatWeOffer} data-animate-on-scroll>
                    What We Offer
                  </div>
                </div>
                <div
                  className={styles.divelementorContainer4}
                  id="cardOpacitySec"
                >
                  <FrameComponent
                    databasepng="60x60x124192591"
                    consulting="Consulting"
                    p="P"
                    rOFITFROMOUREXPERTADVICET="ROFIT FROM OUR EXPERT ADVICE TO BRING YOU REAL SOLUTIONS"
                    propBorder="1px solid rgba(14, 201, 173, 0)"
                    propWidth="13.35rem"
                    propAlignItems="flex-start"
                    icon="/images/database.png"
                    animDelay="0.15s"
                  />
                  <FrameComponent
                    databasepng="60x60x-751662399"
                    consulting="Outstaffing"
                    p="H"
                    rOFITFROMOUREXPERTADVICET="IRE OUR SKILFUL TEAM AS YOUR EXTERNAL CONTRACTOR PARTNER"
                    propBorder="1px solid var(--color-mediumaquamarine-200)"
                    propWidth="9.83rem"
                    propAlignItems="center"
                    icon="/images/data-security.png"
                    animDelay="0.30s"
                  />
                  <FrameComponent
                    databasepng="60x60x498985216"
                    consulting="Outsourcing"
                    p="H"
                    rOFITFROMOUREXPERTADVICET="AVE ONE OF OUR TOP MEMBERS BASED IN YOUR COMPANY FOR A PERIOD OF TIME"
                    propBorder="1px solid var(--color-mediumaquamarine-200)"
                    propWidth="10.72rem"
                    propAlignItems="center"
                    icon="/images/cloud.png"
                    animDelay="0.45s"
                  />
                </div>
              </section>
              <section className={styles.servicessection} id="servicesSection">
                <div className={styles.divelementorContainer5}>
                  <div className={styles.frameParent8}>
                    <FrameComponent1
                      seminars="Seminars"
                      ourExperiencedProfessiona="Our experienced professionals deliver highly relevant conferences in exclusive sessions. By invitation only."
                      animDelay="0.15s"
                    />
                    <FrameComponent1
                      seminars="Professional Training"
                      ourExperiencedProfessiona="Our experts can equip your team members to respond to your needs through professional training sessions in your own facilities."
                      animDelay="0.45s"
                    />
                  </div>
                </div>
              </section>
              <section
                className={styles.checklocationsection}
                id="checkLocationSection"
                data-animate-on-scroll
              >
                <div className={styles.divelementorWidgetWrapParent}>
                  <div className={styles.divelementorWidgetWrap}>
                    <div className={styles.divjkitIconBoxWrapper}>
                      <div className={styles.divicon}>
                        <img className={styles.icon} alt="" src="/3.svg" />
                      </div>
                      <div className={styles.letsUseOurServiceWrapper}>
                        <div className={styles.letsUseOur}>
                          Let's Use Our Service
                        </div>
                      </div>
                    </div>
                    <div className={styles.h2elementorHeadingTitle}>
                      <div className={styles.letsUseOurServiceWrapper}>
                        <div className={styles.checkAbilityToContainer}>
                          <p className={styles.weAnalyseOur}>
                            Check ability to connect our
                          </p>
                          <p className={styles.weAnalyseOur}>
                            services in your area
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.frameWrapper}>
                    <form className={styles.frameForm} id="locationForm">
                      <div className={styles.emailWrapper}>
                        <input
                          className={styles.email}
                          type="text"
                          defaultValue="Check"
                          placeholder="   Your location..."
                          id="user_location_check"
                        />
                      </div>
                      <button className={styles.submit}>
                        <div className={styles.check}>Check</div>
                      </button>
                    </form>
                  </div>
                </div>
              </section>
              <section
                className={styles.contactsection}
                id="contactSection"
                data-scroll-to="contactSection"
              >
                <div className={styles.text} />
                <div className={styles.divelementorWidgetWrap1}>
                  <form
                    // CustomCODE: submit
                    onSubmit={sendEmail}
                    className={styles.divelementorWidgetWrapInner}
                    id="contactForm"
                  >
                    <div className={styles.frameParent1}>
                      <div className={styles.frameParent10}>
                        <div className={styles.contactUsParent}>
                          <div
                            className={styles.contactUs}
                            data-animate-on-scroll
                          >
                            Contact us
                          </div>
                          <div
                            className={styles.telcometricsRoomC6Container}
                            data-animate-on-scroll
                          >
                            <span
                              className={styles.telcometricsRoomC6Container1}
                            >
                              <p className={styles.weAnalyseOur}>
                                <b className={styles.telcometrics1}>
                                  Telcometrics Limited
                                </b>
                              </p>
                              <p className={styles.roomC64f}>
                                Room C6, 4/F, Shun Pont Building,
                              </p>
                              <p className={styles.roomC64f}>
                                No. 5-7 Thomson Road, Wan Chai,
                              </p>
                              <p className={styles.roomC64f}>Hong Kong</p>
                            </span>
                          </div>
                        </div>
                        <div className={styles.frameParent11}>
                          <div className={styles.nameParent}>
                            <input
                              className={styles.name}
                              type="text"
                              placeholder="Your name"
                              id="user_name"
                              data-animate-on-scroll
                              // CustomCODE: name
                              value={sendFormNameVal}
                              onChange={(e) => { 
                                setSendFormNameVal(e.target.value);
                                checkNameValidation(e.target.value);
                              }}
                            />
                            <input
                              className={styles.email1}
                              type="email"
                              placeholder="Your email"
                              id="user_email"
                              data-animate-on-scroll
                              // CustomCODE: email
                              value={sendFormEmailVal}
                              onChange={(e) => { 
                                setSendFormEmailVal(e.target.value);
                                checkEmailValidation(e.target.value);
                              }}
                            />
                          </div>
                          <div className={styles.messageParent}>
                            <textarea
                              className={styles.message}
                              placeholder="Your message"
                              id="user_message"
                              data-animate-on-scroll
                              // CustomCODE: message
                              value={sendFormMessageVal}
                              onChange={(e) => { 
                                setSendFormMessageVal(e.target.value);
                                checkMessageValidation(e.target.value);
                              }}
                            />
                            <div
                              className={styles.submitWrapper}
                              data-animate-on-scroll
                            >
                            <button
                              className={styles.submit1}
                              data-animate-on-scroll
                              // CustomCODE: submit button
                              type="submit"
                              //onClick={() => { sendFormData(true); }}
                              disabled={!checkEmail && !checkName && !checkMessage}
                              style={{
                                cursor: checkEmail && checkName && checkMessage ? 'pointer' : 'not-allowed',
                              }}
                            >
                                <div className={styles.readMore}>Submit</div>
                              </button>
                            </div>
                            <div className={styles.divplaceholder}>
                              {/* name validation */}
                              <div style={{ display: sendFormNameVal.length > 1 && !checkName || sendFormMessageVal.length > 1 && !checkMessage ? 'flex' : 'none' }} className={styles.shortMessage3}>
                                {'Name or Message is empty.'}
                              </div>

                              {/* email validation */}
                              <div style={{ display: sendFormEmailVal.length > 1 && !checkEmail ? 'flex' : 'none' }} className={styles.shortMessage2}>
                                {'Please enter a valid email.'}
                              </div>

                              {/* message validation */}
                              <div style={{ display: sendForm.length > 1 && !hideFieldMessage ? 'flex' : 'none' }} className={styles.shortMessage3}>
                                {sendForm === 'OK' ? sendMessagesData.success : sendMessagesData.error}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className={styles.submit3}>
                        <div className={styles.check}>Submit</div>
                      </button>
                    </div>
                  </form>
                  <div className={styles.divelementorBackgroundOverl5} />
                </div>
              </section>
              <section
                className={styles.newslettersection}
                id="newsletterSection"
              >
                <div className={styles.divelementorWidgetWrap2}>
                  <div className={styles.subscribeOurNewsletterParent}>
                    <div className={styles.subscribeOurNewsletter}>
                      Subscribe Our Newsletter
                    </div>
                    <div className={styles.divelementorWidgetContainer1}>
                      <div className={styles.loremIpsumDolorContainer}>
                        <span className={styles.loremIpsumDolorContainer1}>
                          <p className={styles.weAnalyseOur}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </p>
                          <p className={styles.weAnalyseOur}>
                            incididunt ut labore et dolore magna aliqua.
                          </p>
                        </span>
                      </div>
                    </div>
                    <div className={styles.divelementskitFormWraper}>
                      <input
                        className={styles.email2}
                        type="text"
                        placeholder="Your email address"
                      />
                      <button className={styles.submit5}>
                        <div className={styles.check}>Subscribe</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.divelementorBackgroundOverl5} />
              </section>
            </div>
            <div className={styles.sectionelementorSection1}>
              <div className={styles.divelementorContainer6} />
            </div>
            <div className={styles.sectionelementorSection2} />
            <div className={styles.sectionelementorSection3} />
            <div className={styles.sectionelementorSection4}>
              <div className={styles.divelementorWidgetWrap3}>
                <div className={styles.divelementorContainer7} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TelcometricsReactWeb02;
