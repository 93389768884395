import { FunctionComponent, useMemo, useEffect } from "react";
import CSS, { Property } from "csstype";
import styles from "./CardAssess.module.css";

type CardAssessType = {
  assessmentDimensions?: string;
  assessmentText?: string;
  text?: string;
  br?: number;

  /** Style props */
  propWidth?: Property.Width;
  propHeight?: Property.Height;
  propWidth1?: Property.Width;
  propLeft?: Property.Left;
  propWidth2?: Property.Width;
};

const CardAssess: FunctionComponent<CardAssessType> = ({
  assessmentDimensions,
  assessmentText,
  text,
  br,
  propWidth,
  propHeight,
  propWidth1,
  propLeft,
  propWidth2,
}) => {
  const iconStyle: CSS.Properties = useMemo(() => {
    return {
      width: propWidth,
      height: propHeight,
    };
  }, [propWidth, propHeight]);

  const weAssessStyle: CSS.Properties = useMemo(() => {
    return {
      width: propWidth1,
    };
  }, [propWidth1]);

  const loremIpsumDolorContainerStyle: CSS.Properties = useMemo(() => {
    return {
      left: propLeft,
      width: propWidth2,
    };
  }, [propLeft, propWidth2]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div
      className={styles.divjkitIconBoxWrapper4}
      id="NeedsBoxCard1"
      data-animate-on-scroll
    >
      <img
        className={styles.icon}
        alt=""
        src={assessmentDimensions}
        style={iconStyle}
      />
      <div className={styles.diviconBox}>
        <div className={styles.weAssess} style={weAssessStyle}>
          {assessmentText}
        </div>
        <div className={styles.piconBoxDescription}>
          <div
            className={styles.loremIpsumDolorContainer}
            style={loremIpsumDolorContainerStyle}
          >
            <p className={styles.loremIpsumDolor}>
              {text}
              {br === 1 && <br />}
              {br === 1 && <br />}
              
              {br === 2 && <br />}
              {br === 2 && <br />}
              {br === 2 && <br />}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAssess;
