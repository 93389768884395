import { FunctionComponent, useEffect } from "react";
import styles from "./FrameComponent1.module.css";

type FrameComponent1Type = {
  seminars?: string;
  ourExperiencedProfessiona?: string;
  animDelay?: string;
};

const FrameComponent1: FunctionComponent<FrameComponent1Type> = ({
  seminars,
  ourExperiencedProfessiona,
  animDelay,
}) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div 
    className={styles.seminarsParent} data-animate-on-scroll
    style={{ animationDelay: animDelay }}
    >
      <div className={styles.seminars}>{seminars}</div>
      <img className={styles.frameChild} alt="" src="/vector-7.svg" />
      <div className={styles.ourExperiencedProfessionals}>
        {ourExperiencedProfessiona}
      </div>
    </div>
  );
};

export default FrameComponent1;
